import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/orange.png"
import {IoMdArrowRoundBack} from "react-icons/io"
import firebase from './fire-base.js'
import auth from 'firebase/app'
import "firebase/auth"
import { navigate } from "gatsby"
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications'

const useStyles = ()=>({
  wrapper: {
      position: 'relative',
      marginTop: 20,
    },
    buttonProgress: {
      color: 'green',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    submitButton:{
      backgroundColor:'#e7523a',
      color:'white',
      fontWeight: 'bold',
      width:'100%',
      '&:hover':{
        backgroundColor:'#e7523a!important'
      }
    }
});

class SignInComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      error: null,
      isSignIn:false,
    }
    this.displayError = React.createRef()
  }

  handleSignInInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  async log() {
    await firebase.analytics().logEvent('Email Login', { name: 'Email'})
  }

 

   handleSubmit = async(event) => {
     
    event.preventDefault()
    
      const { email, password } = this.state
      if(email !== "" && password !== "") {
        this.setState({isSignIn: true})
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(async(user) => {
            this.log()
            console.log(user.user.uid);
            localStorage.setItem('cloude_pin_user_key', user.user.uid)
            this.requiresCompleteProfile(user.user)
            this.displayError.current.hidden = true

            setTimeout(function(){
              NotificationManager.success('Success');
            }, 5000);

          })
          .catch((error) => {
            this.setState({error: "Invalid Email or password"})
            this.displayError.current.hidden = false
            setTimeout(()=>{ this.displayError.current.hidden = true }, 1000)
            this.setState({isSignIn: false})
            NotificationManager.error(error.message)
          })
      }
      else {
         this.setState({error: "All Fields Required"})
         this.displayError.current.hidden = false
         setTimeout(()=>{ this.displayError.current.hidden = true }, 1000)
      }
   }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }

  async googleLog() {
    await firebase.analytics().logEvent('Google Login', { name: 'Google'})
  }

  async requiresCompleteProfile(resultUser) {
    await firebase.firestore().collection("users").doc(resultUser.uid).get().then((user)=> {
        NotificationManager.success("Log in Success")
        this.setState({isSignIn: false})
        if(user.data().role==='admin'){
          navigate("/admin/dashboard")
        } else {
          if(user.exists) {
            const username = user.data().username
              ? user.data().username
              : ""
            if(username !== "" && username !== null) {
              navigate("/home/")
            } else {
             navigate("/completeprofile/")
            }
          } else {
             navigate("/completeprofile/")
          }
        }
     })
  }

  handleGoogleSubmit() {
    var provider = new auth.auth.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    firebase.auth().signInWithPopup(provider).then((result) => {
      var user = result.user
      this.googleLog()
      this.requiresCompleteProfile(user)
    }).catch((error) => {})
  }

  submit = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  render() {
    const {classes}= this.props;
    
    return (
       <>
       <NotificationContainer />
        <div className = "auth-header"><Link to="/"><IoMdArrowRoundBack className="back-arrow"/></Link><img src = {Logo} className = "auth-logo" alt=""/></div>
        <div className = "auth-form-wrapper">
          <form className = "auth-form col-sm-6 col-md-6 col-lg-4" onSubmit={this.submit}>
            <h4 className = "text-center">Sign in</h4>
            <p className = "text-center">Need an account? <Link to="/signup" className = "auth-link">Sign up</Link></p>
            <div className="text-center">
            <button type="button" className = "auth-btn-google col-md-10 text-center" onClick={()=>this.handleGoogleSubmit()}>Continue with Google</button>
            </div>
            <div className="form-group text-center px-5">
              <TextField
                    type='email'
                    className='w-100 mt-3'
                    placeholder='Email'
                    style={{ fontSize: '24px' }}
                    value={this.state.email}
                    onChange={this.handleSignInInputChange}
                    name="email"
                    disabled={this.state.isSignIn}
                />
            </div>
            <div className = "form-group text-center px-5">
              <TextField
                    type='password'
                    className='w-100 mt-3'
                    placeholder='Password'
                    style={{ fontSize: '24px' }}
                    value={this.state.password}
                    onChange={this.handleSignInInputChange}
                    name="password"
                    disabled={this.state.isSignIn}
                />
            </div>
            <div className="text-center">
              <p className= "auth-text offset-md-1 col-md-10">By clicking Sign up, Continue with Facebook, or Continue with Google, you agree to our <Link to="/" className = "auth-link">Terms and Conditions</Link> and <Link to="/" className = "auth-link">Privacy Statement</Link></p>
            </div>
            <div className="text-center px-5">
              <div className={classes.wrapper}>
                  <Button 
                    type="submit" 
                    className = {classes.submitButton}
                    onClick={this.handleSubmit}
                    disabled={this.state.isSignIn}
                  >
                    Log In
                  </Button>
                  {this.state.isSignIn && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </div>
             <p className = "text-center" style={{ fontSize: "14px" }}>Trouble logging in? <Link to="/forgotpassword" className = "auth-link">Reset Password</Link></p>
            <p hidden id="error" style={{textAlign: "center", color: "red"}} ref={this.displayError}>{this.state.error}</p>
        </form>
      </div>
     </>
    )
  }
}

export default withStyles(useStyles)(SignInComponent)