import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import SignIn from "../components/auth/signin-component"

const SignInPage = () => (
  
 <SignIn></SignIn>
  
)

export default SignInPage
